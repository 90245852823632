<template>
  <base-section id="about-us">
    <base-section-heading
      color="grey lighten-2"
      title="VISI DAN MISI KAMI"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="6"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutUs',

    data: () => ({
      cards: [
        {
          title: 'Visi',
          text: 'Menjadi Broker Asuransi Pilihan Pertama dan Terpercaya di Indonesia.',
        },
        {
          title: 'Misi',
          html: '<ol><li>Memberikan Jasa Risk Management & Insurance Consultant secara professional.</li><li>Menyelenggarakan perusahaan dengan menerapkan prinsip-prinsip tata kelola perusahaan yang baik (Good Corporate Governance).</li><li>Memberikan pemahaman tentang asuransi kepada tertanggung.</li></ol>',
        },
      ],
    }),
  }
</script>
